import React from "react"
import cookieService from "../services/cookieService"
export const LocaleStoreContext = React.createContext()

export class LocaleStore extends React.Component {
  constructor(props) {
    super(props)
    let locale = this.getLocaleFromCookie()
    if (locale !== "en" || locale !== "de") {
      locale = "en"
    }

    this.state = {
      locale: locale,
      changeLanguage: this.setLocale,
    }
  }

  getLocaleFromCookie = () => {
    return cookieService.getValueOfCookie("locale")
  }

  saveLocaleToCookie = locale => {
    const cookie = "locale=" + locale
    cookieService.storeCookieWithExpireInDays(cookie, 365)
  }

  getInitialState() {
    const newState = { locale: "", changeLanguage: this.setLocale }
    const localeFromCookie = this.getLocaleFromCookie()
    if (localeFromCookie === "en" || localeFromCookie === "de") {
      newState.locale = localeFromCookie
      return newState
    } else {
      let locale = this.determineLocale()
      newState.locale = locale
      return newState
    }
  }

  determineLocale() {
    //polyfill
    if (!String.prototype.startsWith) {
      // eslint-disable-next-line
      String.prototype.startsWith = function(searchString, position) {
        position = position || 0
        return this.indexOf(searchString, position) === position
      }
    }
    let locale = ""
    if (
      typeof window !== "undefined" &&
      typeof window.navigator !== "undefined"
    ) {
      locale = navigator.language
    }
    if (locale.startsWith("de")) {
      return "de"
    } else {
      return "en"
    }
  }

  setLocale = locale => {
    if (locale) {
      let newState = this.state
      newState.locale = locale
      this.setState(newState)
      this.saveLocaleToCookie(locale)
    }
  }

  componentDidMount() {
    const initialState = this.getInitialState()
    this.setState(initialState)
    this.saveLocaleToCookie(initialState.locale)
  }

  render() {
    return (
      <LocaleStoreContext.Provider value={this.state}>
        {this.props.children}
      </LocaleStoreContext.Provider>
    )
  }
}
