class CookieService {
  storeCookieWithExpireInDays(cookie, days) {
    if (typeof window !== `undefined`) {
      let date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      const expires = "expires=" + date.toUTCString()
      document.cookie = cookie + "; " + expires + ";path=/"
    }
  }

  getValueOfCookie(name) {
    if (typeof window !== `undefined`) {
      let cookies = document.cookie.split(";")
      for (const cookie of cookies) {
        if (cookie.includes(name + "=")) {
          let value = cookie.split("=")[1]
          return value
        }
      }
    }
    return ""
  }

  setGaOptout() {
    const gaProperty = "UA-139055971-1"
    const disableStr = "ga-disable-" + gaProperty + "=true"
    this.storeCookieWithExpireInDays(disableStr, 365)
    window[disableStr] = true
  }
}

export default new CookieService()
